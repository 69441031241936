<template>
  <div class="container">
    <a class="btn__back" @click="$router.push('/signup/existing-user')">
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5141 20 20 15.5141 20 10C20 4.48586 15.5141 0 10 0C4.48586 0 0 4.48586 0 10C0 15.5141 4.48586 20 10 20ZM6.21121 9.68795L11.4263 4.47283C11.5115 4.38763 11.6228 4.34591 11.734 4.34591C11.8453 4.34591 11.9565 4.38849 12.0417 4.47283C12.2112 4.64232 12.2112 4.91786 12.0417 5.08735L7.13429 9.99479L12.0409 14.9031C12.2104 15.0726 12.2104 15.3481 12.0409 15.5176C11.8714 15.6871 11.5958 15.6871 11.4263 15.5176L6.21121 10.3025C6.04172 10.133 6.04172 9.85744 6.21121 9.68795Z"
          fill="#DB353A"
        />
      </svg>
      <span>Back</span>
    </a>

    <h2 class="leading leading__card">Choose a card and complete all fields</h2>

    <section class="cards">
      <div class="cards__wrapper">
        <!-- <div class="card"> -->
        <div
          class="card"
          v-for="card in userDetails.cards"
          :key="card.pan"
          @click="setCardDetails(card)"
        >
          <div class="card__top">
            <svg
              v-if="card.cardtype === 'SBP_MASTERCARD'"
              width="33"
              height="20"
              viewBox="0 0 33 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.994 9.99994C19.994 15.524 15.519 19.9999 9.99698 19.9999C4.47495 19.9999 0 15.523 0 9.99994C0 4.47692 4.47604 0 9.99698 0C15.5179 0 19.994 4.47692 19.994 9.99994Z"
                fill="#E2574C"
              />
              <path
                d="M22.003 0C19.761 0 17.697 0.747013 16.029 1.99404L16.037 1.99503C16.365 2.312 16.727 2.53508 17.006 2.89998L14.926 2.93299C14.6 3.26202 14.303 3.62001 14.023 3.99204H17.6909C17.9699 4.32699 18.2279 4.61808 18.462 4.98802H13.358C13.171 5.31003 12.998 5.64104 12.847 5.98499H19.043C19.205 6.32795 19.35 6.587 19.4731 6.95004H12.48C12.369 7.29508 12.2741 7.64802 12.202 8.00799H19.766C19.84 8.35402 19.897 8.67395 19.936 9.00002H12.052C12.019 9.32905 12.002 9.66302 12.002 10.0001H19.9929C19.9929 10.3541 19.9679 10.682 19.932 11.0001H12.052C12.086 11.3391 12.136 11.6721 12.202 12.0001H19.754C19.676 12.3241 19.5859 12.6501 19.473 12.9881H12.457C12.563 13.3301 12.692 13.6621 12.833 13.9861H19.043C18.871 14.3501 18.676 14.6411 18.4611 14.982H13.3401C13.5422 15.332 13.7651 15.666 14.0071 15.986L17.691 16.041C17.377 16.4179 16.9741 16.645 16.607 16.975C16.6269 16.991 16.02 16.973 14.8249 16.954C16.643 18.8301 19.184 20 22.0029 20C27.5259 20 32.0028 15.523 32.0028 10.0001C32.0028 4.47714 27.5269 0 22.003 0Z"
                fill="#F4B459"
              />
            </svg>
          </div>
          <div class="card__num">{{ card.pan | formatCard }}</div>
          <div class="card__name">
            <span>CARD HOLDER</span>{{ userDetails.accountname }}
          </div>
        </div>
        <!-- </div> -->
      </div>
    </section>

    <form
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      v-if="cardSelected"
    >
      <div class="form__group">
        <label for="cardNumberLeft" class="form__label form__card-pan">
          <input
            type="text"
            id="cardNumberLeftOne"
            name="cardNumberLeftOne"
            class="form__input"
            v-model.lazy="$v.form.cardNumberLeftOne.$model"
            disabled
          />
          <input
            type="text"
            id="cardNumberLeftTwo"
            name="cardNumberLeftTwo"
            class="form__input"
            v-model.lazy="$v.form.cardNumberLeftTwo.$model"
            disabled
          />
          <input
            type="text"
            maxlength="4"
            id="cardNumberCenter"
            name="cardNumberCenter"
            class="form__input"
            placeholder="****"
            v-model.lazy="$v.form.cardNumberCenter.$model"
            :class="{
              'is-invalid': submitted && $v.form.cardNumberCenter.$error
            }"
          />
          <input
            type="text"
            maxlength="4"
            id="cardNumberRight"
            name="cardNumberRight"
            class="form__input"
            v-model.lazy="$v.form.cardNumberRight.$model"
            disabled
          />
        </label>
        <small
          class="error"
          v-if="submitted && !$v.form.cardNumberCenter.required"
          >Card pan is required</small
        >
        <!-- <br /> -->
        <small
          class="error"
          v-if="submitted && !$v.form.cardNumberCenter.numeric"
          >Needs to be numeric only.</small
        >
      </div>
      <div class="form__group">
        <label for="cardPin">
          <input
            id="cardPin"
            :type="passwordFieldType"
            name="cardPin"
            placeholder="Card PIN"
            class="form__input"
            v-model="$v.form.cardPin.$model"
            :class="{ 'is-invalid': submitted && $v.form.cardPin.$error }"
        /></label>
        <small class="error" v-if="submitted && $v.form.cardPin.$error"
          >Card pin is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.cardPin.numeric"
          >Needs to be numeric only.</small
        >
        <span @click="switchVisibility" class="form__password">{{
          passwordFieldType === "password" ? "Show" : "Hide"
        }}</span>
      </div>
      <div class="form__group">
        <label for="cardExpiry" class="form__label">
          <input
            type="text"
            id="cardExpiry"
            name="cardExpiry"
            placeholder="Expiry Date (MM/YY)"
            @keyup="formatString"
            class="form__input"
            v-model.lazy="$v.form.cardExpiry.$model"
            :class="{ 'is-invalid': submitted && $v.form.cardExpiry.$error }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.cardExpiry.required"
          >Expiry date is required</small
        >
      </div>
      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Continue</span>
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  computed: mapState(["userDetails"]),
  data() {
    return {
      cardSelected: false,
      passwordFieldType: "password",
      loading: false,
      submitted: false,
      form: {
        cardNumberLeftOne: "",
        cardNumberLeftTwo: "",
        cardNumberCenter: "",
        cardNumberCenterCheck: "",
        cardNumberRight: "",
        cardPin: "",
        cardExpiry: "",
        cardExpiryCheck: "",
        seqno: ""
      }
    };
  },
  validations: {
    form: {
      cardNumberLeftOne: { required, numeric },
      cardNumberLeftTwo: { required, numeric },
      cardNumberCenter: { required, numeric },
      cardNumberRight: { required, numeric },
      cardPin: { required, numeric },
      cardExpiry: { required }
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    setCardDetails(value) {
      this.form.seqno = value.seqno;

      let cardnumber = value.pan;

      let firstFour = cardnumber.slice(0, 4);
      let lastFour = cardnumber.slice(-4);
      let maskedNumber = cardnumber.slice(-8, -4);
      let remainingNumber = cardnumber.slice(4, -8);
      // this.form.cardNumberCenter = maskedNumber.replace(/\d/g, "*");

      this.form.cardNumberLeftOne = firstFour;
      this.form.cardNumberLeftTwo = remainingNumber;
      this.form.cardNumberCenterCheck = maskedNumber;
      this.form.cardNumberRight = lastFour;

      this.form.cardExpiryCheck = value.exp;

      this.cardSelected = true;
    },
    formatString(event) {
      let code = event.keyCode;
      let allowedKeys = [8];
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }

      event.target.value = event.target.value
        .replace(/^([1-9]\/|[2-9])$/g, "0$1/")
        .replace(/^(0[1-9]|1[0-2])$/g, "$1/")
        .replace(/^([0-1])([3-9])$/g, "0$1/$2")
        .replace(/^(0?[1-9]|1[0-2])([0-9]{2})$/g, "$1/$2")
        .replace(/^([0]+)\/|[0]+$/g, "0")
        // eslint-disable-next-line no-useless-escape
        .replace(/[^\d\/]|^[\/]*$/g, "")
        .replace(/\/\//g, "/");
    },

    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      if (this.form.cardNumberCenter !== this.form.cardNumberCenterCheck) {
        this.$alert(`Incorrect Card Pan`, "Error", {
          confirmButtonText: "OK",
          type: "error",
          dangerouslyUseHTMLString: true
        });
        return;
      }

      let enteredCard = this.form.cardExpiry;
      let cardWithoutSlash = enteredCard.replace("/", "");
      let expMonth = cardWithoutSlash.slice(0, 2);
      let expYear = cardWithoutSlash.slice(2, 4);
      let sendExpFormat = expYear + expMonth;

      if (sendExpFormat !== this.form.cardExpiryCheck) {
        this.$alert(`Incorrect Card Expiry Date`, "Error", {
          confirmButtonText: "OK",
          type: "error",
          dangerouslyUseHTMLString: true
        });
        return;
      }

      let payload = {
        pan: `${this.form.cardNumberLeftOne}${this.form.cardNumberLeftTwo}${this.form.cardNumberCenter}${this.form.cardNumberRight}`,
        exp: sendExpFormat,
        seqno: this.form.seqno,
        cardpin: this.form.cardPin
      };

      this.loading = true;

      this.$store.dispatch("setCardDetails", payload);
      this.$router.push("/signup/existing-user-form");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
